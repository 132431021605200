<template>
  <div class="row">
    <div class="col-12 col-lg-6 c">
        <div class="card">
            <div class="card-body">
                <div class="form-group">
                  <label for="">الارقام</label>
                  <textarea class="form-control" v-model="numbers" rows="10"></textarea>
                </div>
                <br>
                <div class="form-group">
                  <label for="">JWT</label>
                  <input type="text"
                    class="form-control" v-model="sendjwt">
                </div>
                <div class="form-group">
                  <label for="">Devices ( split with , )</label>
                  <input type="text"
                    class="form-control" v-model="senddevices" placeholder="1234,3435">
                </div>
                <div class="form-group">
                  <label for="">الرسالة</label>
                  <textarea class="form-control" v-model="message" rows="3"></textarea>
                </div>
                <div class="form-group">
                  <label for="">رابط الملف</label>
                  <input type="url"
                    class="form-control" v-model="file">
                </div>
                <div class="form-group">
                  <label for="">المدة بين كل رسالة (بالثواني)</label>
                  <input type="number"
                    class="form-control" v-model="sendinterval">
                </div>
                <div class="g">
                    <div class="border g">
                        الوقت الآن: {{now}}
                    </div>
                </div>
                <div class="form-group">
                  <label for="">البداية</label>
                  <input type="time"
                    class="form-control" v-model="start_time">
                </div>
                <div class="form-group">
                  <label for="">النهاية</label>
                  <input type="time"
                    class="form-control" v-model="end_time">
                </div>
                <div class="col-12 text-center">
                    <span v-if="loading2" class="btn btn-outline-danger">جاري المعالجة...</span>
                    <button v-if="!loading2" class="btn btn-primary" @click="createNow()">
                        بدء المعجنة
                    </button>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            loading: true,
            selectedtags: [],
            message: "",
            file: "",
            sendjwt: "",
            senddevices: "",
            sendinterval: 60,
            loading2: false,
            numbers: "",
            obj: [],
            start_time: "08:00",
            end_time: "20:00",
            now: 0,
            user: JSON.parse(localStorage.getItem("user")),
            
        }
    },
    methods: {
        createNow(){
            var g = this;
            g.loading2 = true;
            $.post(marketing + "/create-operation", {
                jwt: g.user.jwt,
                file: g.file,
                message: g.message,
                tags: g.selectedtags,
                send_jwt: g.sendjwt,
                devices: g.senddevices,
                numbers: g.numbers,
                interval: g.sendinterval,
                start_time: g.start_time,
                end_time: g.end_time
            }).then(function(r){
                window.location = '/marketing/list'
            }).catch(function(e){
                alert("error " + e)
            })
        }
    },
    created(){
        var g = this;
        $.post(marketing + "/stats", {
            jwt: this.user.jwt,
        }).then(function(a){
            g.now = a;
    })
    }
}
</script>

<style>

</style>